const ROUTES_NAME = {
  HOME: "/",
  PROPERTY_MANAGEMENT: "/services/property-management",
  PROFILE: "/profile",
  PROPERTY_VALUATION: "/services/valuation",
  PROPERTIES: "/properties",
  LEGEL_ASSISTANCE: "/services/legal",
  MORTGAGE_SERVICE: "/services/mortgage",
  ABOUT: "/about",
  CONTACT: "/contact",
  LOGIN:'/login',
  PRIVACY_POLICY: '/privacy-policy',
  HOME_INSPACTION: '/services/inspection',
  SERVICE_INVESTMENT: '/services/investment',
  REAL_ESTATE_ADVICE: '/real-estate/advice',
  // Professional services routes
  AGENTS_SERVICE: '/services/agents',
  BUILDERS_SERVICE: '/services/builders',
  ARCHITECTS_SERVICE: '/services/architects',
  INTERIOR_DECORATORS_SERVICE: '/services/interior-decorators',
  VAASTU_SERVICE: '/services/vaastu',
  CONTRACTORS_SERVICE: '/services/contractors',
  CONSULTANTS_SERVICE: '/services/consultants',
  POST_A_PROPERTY: '/post-property',
  POST_A_PROJECT: '/post-project',
  POST_PROPERTY: '/post_a_property',
  VIEW_PROPERTY: '/property-profile',
  VIEW_PROJECT: '/project-profile',
  VIEW_BROKER: '/broker/:id',
  SEARCH: '/search',
  ADMIN_LOGIN : '/admin/login',
  ADMIN_HOME : '/admin/dashboard',
  ADMIN_ALL_PROPERTIES: '/admin/properties/all',
  BROKER_ALL_PROPERTIES: '/admin/brokers/properties/all',
  USER_ALL_PROPERTIES: '/admin/users/properties/all',
  ADMIN_MANAGE_SALLERS: '/admin/sallers/manage',
  ADMIN_MANAGE_USERS: '/admin/users/manage',
  ADMIN_SALLERS_REQUESTS: '/admin/brokers/requests',
  ADMIN_ALL_PAYMENTS: '/admin/all/payments',
  ADMIN_MANAGE_SUBSCRIPTION : '/admin/subscription/manage',
  ADMIN_EDIT_SUBSCRIPTION : '/admin/subscription/edit',
  ADMIN_CHANGE_PASSWORD : '/admin/change-password',
  
  SEARCH_RESULTS: '/search-results',

  SALLER_HOME : '/saller/dashboard',
  SALLER_LOGIN : '/saller/login',
  SALLER_SIGNUP : '/saller/signup',
  SALLER_UPDATE_PROFILE : '/saller/update-profile',
  SALLER_CHANGE_PASSWORD : '/saller/change-password',
  SALLER_PROPERTY_ADD: '/saller/property/add',
  SALLER_MANAGE_QUERIES: '/saller/queries/manage',
  SALLER_ALLPROPERTIES: '/saller/properties',
  SALLER_ADD_PROJECT: '/saller/project/add',
  SALLER_ALL_PROJECTS: '/saller/projects',
};

export default ROUTES_NAME;
